import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/aida.jpg" // Tell webpack this JS file uses this image
import pal13 from "../images/palestrantes/abuelo.png" // Tell webpack this JS file uses this image

import pal14 from "../images/palestrantes/bart.png" // Tell webpack this JS file uses this image

import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import Footer from "../components/footer"
import pal33 from "../images/palestrantes/diogogama.png" // Tell webpack this JS file uses this image
import pal35 from "../images/palestrantes/martinez.png" // Tell webpack this JS file uses this image

import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
          <div className="texto inter-bold">
             <span className="opacity"> Curso de diagnóstico clínico<br></br> avançado</span> <br></br>
              em Bovinos
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
               

                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal13} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Dr. Angel Abuelo
                      </div>
                    
                    <div className="titulo inter-light">
                   Médico Veterinário
                      </div>
                      </div>
                    
                  </div>

                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal14} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Dr. Bart Pardon
                      </div>
                    
                    <div className="titulo inter-light">
                   Médico Veterinário
                      </div>
                      </div>
                    
                  </div>
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                        11 e 12 de Dezembro de 2023
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                      Inglês
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      485€ + IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/diagnostico-clinico.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://forms.gle/8WTeXJpBvhmgnchR6" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">
              Este curso destina-se a médicos veterinários que pretendam
aumentar as suas capacidades de diagnóstico em bovinos,
equipando-os com conhecimentos práticos e teóricos, que
lhes permitam facilmente alcançar diagnósticos precisos.
O curso incluirá ferramentas de diagnóstico para animais
individuais e para medicina do efetivo, seja em doenças
metabólicas, seja em doenças infecciosas.
                </div>
                <div className="logos">
                  
{/*               
                <img src={logo2} alt="" /> */}

                  </div>
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
          {/* <div className="text inter-regular">
          Orientado a Médicos e Enfermeiros Veterinários, Engenheiros Zootécnicos, tecnicos avícolas ou equivalente com mínimo de 1 ano de experiência em avícultura e que pretendam realizar auditorias internas WFQ em galinhas poedeiras.             </div> */}
      </div>
      <div className="wrapper-program">
      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - 11/12/2023
                            </Accordion.Header>

                            <Accordion.Body>
                               
                            <div class="modulo">
                                <p><span className="date">09:10 - 09:45</span> | <span class="mod">Módulo 1</span> - <span class="txt">
                                  
                                Antimicrobial decision making for BRD:
identification of animals in need of
treatment

                                  </span></p>
                                </div> 
                              
                                <div class="modulo">
                                  <p><span className="date">09:45 - 10:45</span> | <span className="mod">Módulo 2</span> - <span class="txt">Lung ultrasound image recognition</span></p>
                                </div>
                              
                              
                                <div class="modulo">
                                  <p><span className="date">10:45 - 11:15</span> | <span className="mod">Coffee-break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:15 - 12:15</span> | <span className="mod">Módulo 3</span> - <span class="txt">Evaluating the success or failure of transition
dairy cows</span></p>
                                </div>
   
                                <div class="modulo">
                                  <p><span className="date">12:15 - 13:15</span> | <span className="mod">Módulo 4</span> - <span class="txt">Metabolic cow health monitoring (hyperketonemia, hypocalcemia):
Goals and principles to set up a routine herd health diagnostic
program</span></p>
                                </div>

                                <div class="modulo">
                                  <p><span className="date">13:15 - 14:30</span> | <span className="mod">Lunch</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 15:30</span> | <span className="mod">Módulo 5</span> - <span class="txt">On farm application of QTUS - Quick Thoracic Ultrassound Scan
</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:30 - 16:30</span> | <span className="mod">Módulo 6</span> - <span class="txt">Sampling, laboratory diagnostics and interpretation for BRD
</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:30 - 17:00</span> | <span className="mod">Módulo 7</span> - <span class="txt">Evaluation of a transition cow feeding programs
</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">17:00 - 17:15</span> | <span className="mod">Coffee-break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">17:15 - 17:45</span> | <span className="mod">Módulo 8</span> - <span class="txt">Evaluation of a transition cow feeding programs (cont.)
</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">17:45 - 18:45</span> | <span className="mod">Módulo 9</span> - <span class="txt">Colostrum management: factors affecting quality and quantity of
colostrum; assessment of colostrum quality on-farm
</span></p>
                                </div>
                               
                    

                                
                                </Accordion.Body>

                                </Accordion.Item>
                        

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            +   Dia 2 - 12/12/2023 
                            </Accordion.Header>

                            <Accordion.Body>
                            <div class="modulo">
                                <p><span className="date">08:30 - 10:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Detection of clinical signs and comparing them
with lung ultrasound findings</span></p>
                                </div>     
                                <div class="modulo">
                                <p><span className="date">10:00 - 10:45</span> | <span class="mod">Módulo 2</span> - <span class="txt">Deep Nasopharyngeal swab & Broncho-alveolar lavage</span></p>
                                </div>                         
                                <div class="modulo">
                                  <p><span className="date">10:45 - 11:00</span> | <span className="mod">Coffee-break</span></p>
                                </div>

                                <div class="modulo">
                                <p><span className="date">11:00 - 12:30</span> | <span class="mod">Módulo 3</span> - <span class="txt">On-farm evaluation of transition cow nutrition</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">12:30 - 13:30</span> | <span className="mod">Travel back to FarmIN</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:30 - 14:45</span> | <span className="mod">Lunch</span></p>
                                </div>
                                <div class="modulo">
                                <p><span className="date">14:45 - 17:30</span> | <span class="mod">Módulo 4</span> - <span class="txt">Q&A session with results/data for interpretation and discussion</span></p>
                                </div> 
                                
                                </Accordion.Body>
                                </Accordion.Item>

                                
                           
                      
                       
      
                       
               
                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/diagnostico-clinico.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button>
                </a> 
                </div>
                <div className="form">
                <a href="https://forms.gle/8WTeXJpBvhmgnchR6" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 

                </a>
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
      

        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal13} alt="" />

              </div>
              <div className="nome">
              Angel Abuelo
                            </div>
              <div className="titulo">
              Médico Veterinário 

                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span>Veterinário e Doutorado pela Universidade de Santiago de
Compostela (Espanha) e mestrado pelo Royal Veterinary College
(Reino Unido)
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Diplomado pelo American Board of Veterinary Practitioners,
European College of Bovine Health Management, American College
of Veterinary Internal Medicine
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>

                  Professor Associado na Faculdade de Medicina Veterinária da
Michigan State University (MSU)</p>

<p><span className="icone"><CheckIcon></CheckIcon></span>

A sua investigação centra-se em estratégias para melhorar a
resiliência do hospedeiro durante os períodos de maior risco de
doenças em bovinos leiteiros: os períodos neonatal e
periparturiente</p>
                 

                 
                  </div>
              </div>
            </div>
        </div>
        </div>


        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal14} alt="" />

              </div>
              <div className="nome">
              Bart Pardon
                            </div>
              <div className="titulo">
              Médico Veterinário 

                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span>Veterinário e professor associado de
medicina interna de ruminantes na Universidade de Ghent
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Diplomado pelo Colégio Europeu de Gestão da Saúde Bovina e
especializado em gestão da saúde dos vitelos, em particular
doença respiratória bovina. A sua investigação centra-se no
desenvolvimento de diagnósticos, terapia, prevenção e
controlo de problemas respiratórios
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>

                  Recentemente, co-fundou a qTUS, uma empresa spin-off da
UGhent com o objetivo de levar a ultrassonografia pulmonar e
outras ferramentas no maneio respiratório bovino aos
veterinários, de forma sustentável</p>

                 

                 
                  </div>
              </div>
            </div>
        </div>
        </div>
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
